module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-161904319-1","anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://vetovcekozy.cz"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"VetOvceKozy","short_name":"VetOvceKozy","start_url":"/","background_color":"#64af5f","theme_color":"#64af5f","display":"minimal-ui","icon":"src/images/icon.png","icon_options":{"purpose":"maskable"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
